<template>
  <div class="industry">
    <Crumbs :breadList="crumbs" />
    <div class="main">
      <div class="l_box">
        <LeftMenu
          :menus="menus"
          :selectedIndex="selectedIndex"
          :title="'联系我们'"
          @monitorMenus="monitorMenus"
        />
      </div>
      <div class="r_box">
        <div class="base">
          <dl
            class="item"
            v-for="(item, index) in contactData.menuBlockList"
            :key="index"
          >
            <dt><img src="./base-address.png" alt="" /></dt>
            <dd>
              <p class="name">
                {{ item.remark }}
              </p>
              <p>{{ item.sysValue }}</p>
            </dd>
          </dl>
        </div>
        <template v-if="contactData && isMap">
          <!-- <baidu-map class="map" :data="mapObj"></baidu-map> -->
          <img class="map" src="./map.png">
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Crumbs from "@/components/crumbs/index";
import LeftMenu from "@/components/leftMenu/index";
// import baiduMap from "@/components/baiduMap/index";

export default {
  components: {
    Crumbs,
    LeftMenu,
    // baiduMap,
  },
  data() {
    return {
      crumbs: [
        {
          name: "首页",
          link: "/",
        },
        {
          name: "联系我们",
          link: "/contact",
        },
        {
          name: "联系方式",
          link: "",
        },
      ],
      center: { lng: 0, lat: 0 },
      zoom: 3,
      menus: [
        {
          name: "联系方式",
          icon: require("./icon-phone.png"),
          iconSeleted: require("./icon-phone-selected.png"),
        },
      ],
      selectedIndex: 0,
      // 基本信息
      contactData: {
        menuBlockList: [],
      },
      // 地图基本信息
      mapObj: {},
      isMap: false,
    };
  },
  async created() {
    let { status, data } = await this.$api.home.getContact();
    if (status == 200) {
      // 处理结果集块元素集合
      let menuBlockList = []; // 页面展示基本元素
      for (let key in data) {
        // 指定放在第一个位置
        if (key == "BUS_ADDRESS") {
          if (data[key].sysValue) {
            menuBlockList.splice(0, 0, data[key]);
          }
        }
        // 指定放在第一个位置
        if (key == "HQ_ADDRESS") {
          if (data[key].sysValue) {
            menuBlockList.splice(1, 0, data[key]);
          }
        }
        // 接口已经自动排序 按顺序放就可以
        if (
          key == "EXT1" ||
          key == "EXT2" ||
          key == "EXT3" ||
          key == "EXT4" ||
          key == "EXT5"
        ) {
          if (data[key].sysValue) {
            menuBlockList.push(data[key]);
          }
        }
        // 处理地图坐标
        if (key == "MAP_COORD") {
          if (data[key].sysValue) {
            this.mapObj.MAP_COORD = data[key].sysValue;
          }
        }
        // 联系人
        if (key == "LINK_PERSON") {
          if (data[key].sysValue) {
            this.mapObj.LINK_PERSON = data[key].sysValue;
          }
        }
        // 邮箱
        if (key == "LINK_EMAIL") {
          if (data[key].sysValue) {
            this.mapObj.LINK_EMAIL = data[key].sysValue;
          }
        }
        // 地图上显示的联系地址
        if (key == "LINK_ADDRESS") {
          if (data[key].sysValue) {
            this.mapObj.LINK_ADDRESS = data[key].sysValue;
          }
        }
      }
      this.contactData.menuBlockList = menuBlockList; // 页面基本元素
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.isMap = true;
    });
  },
  methods: {
    handler({ BMap, map }) {
      this.center.lng = 116.404;
      this.center.lat = 39.915;
      this.zoom = 15;
    },
    monitorMenus(index, name) {
      this.selectedIndex = index;
      this.crumbs.splice(2, 1, { name: this.menus[this.selectedIndex].label });
    },
  },
};
</script>
<style lang="scss">
@import "./../../assets/style/public.scss";
.industry {
  .base {
    width: 690px;
    float: left;
    background: #fff;
    box-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.06);
    padding: 0 30px;
    margin-bottom: 30px;
    .item {
      width: 100%;
      padding: 28px 0;
      float: left;
      border-bottom: 1px solid #e4e4e4;
      dt {
        width: 46px;
        float: left;
      }
      dd {
        float: left;
        margin-left: 20px;
        p {
          line-height: 23px;
          &.name {
            font-weight: bold;
          }
        }
      }
    }
  }
}
.map {
  width: 100%;
  // height: 400px;
}
</style>
